/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--06 pt--20" anim={null} menu={true} animS={null} border={null}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box" style={{"maxWidth":""}}>
                
                <Subtitle className="subtitle-box fs--20 w--600 swpf--uppercase lh--14 pl--0 pr--0" style={{"maxWidth":""}} content={"TVORBA WEBOVÝCH STRÁNEK"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/"} target={""} content={"Otázky a odpovědi"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/kontakt"} target={""} content={"Kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn1 btn-box--pbtn3 btn-box--shape3 fs--18 w--500 swpf--uppercase lh--1 pl--08 pr--08" innerClassName="pt--10 pb--10" href={"/"} target={""} content={"POPTAT SLUŽBY"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--0 pt--0" name={"sluzby-1"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}}>
        </Column>


        <Column className="--center pb--60 pt--80" name={"uwhjufg46ks"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--10 pl--40 pr--40 pt--50 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <TitleMain className="title-box title-box--center fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Kontakt</span>"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--40 pb--80 pt--10" name={"1p1yygx8jri"}>
          
          <ColumnWrap className="column__flex --center el--2 pb--20 pl--40 pr--40 flex--stretch" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="--hvr2 --shape3 --border2 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"2"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"poptat@tvorba-webovych-stranek.cz&nbsp;<br>"}>
              </Title>

              <Text className="text-box w--500 ls--001 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(109, 112, 134);\">Na e-maily reagujeme v pracovní dny, zpravidla do 24 hodin.&nbsp;&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape3 --border2 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"2"}>
              
              <Title className="title-box fs--30 w--600 ls--004 lh--2" style={{"maxWidth":316}} content={"724 949 630<br>"}>
              </Title>

              <Text className="text-box w--500 ls--001 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(109, 112, 134);\">Telefonický nás zastihnete v pracovní dny od 8:00 do 16:00.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--16" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/12956/2d9d056e7c4e4d8ba96f58c3af94d895.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":214}} srcSet={""} content={null}>
              </Image>

              <Title className="title-box fs--20" content={"Tvorba-webových-stránek.cz"}>
              </Title>

              <Text className="text-box mt--06" style={{"maxWidth":334}} content={"poptat@tvorba-webovych-stranek.cz<br>+420 724 949 630"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"10 let zkušeností\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":269}} content={"Vytváříme moderní webové stránky s minimalistickým a přehledným designem již více jak 10 let."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--right" content={"Navigace"}>
              </Title>

              <Text className="text-box text-box--right" content={"<a href=\"#uvod\">Zpět nahoru<br></a><a href=\"#jak\">Jak to funguje</a><a href=\"#1p1yygx8jri\"><br>Ceník služby</a><a href=\"#uvod\"><br></a><a href=\"#objednavka-1\">Objednávka<br></a><a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}